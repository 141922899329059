import React from 'react'
import { TextareaAutosizeProps } from 'react-textarea-autosize'
let TextareaAutosize = React.lazy(() => import('react-textarea-autosize'))

import * as styles from './Input.styles'

type TextAreaGroupProps = {
  value: string | number
  error?: string | null
  maxLength?: number
  label?: string
  iconClassName?: string
} & TextareaAutosizeProps

const TextAreaGroup: React.FC<TextAreaGroupProps> = ({
  value,
  onChange,
  error,
  placeholder,
  maxLength = 512,
  label,
  iconClassName,
  ...props
}) => {
  return (
    <div css={styles.rootContainer}>
      <div css={styles.metaContainer}>
        <label css={styles.label}>
          {iconClassName && <i css={styles.icon} className={iconClassName} />}
          <span>{label}</span>
        </label>
        {error && <span css={styles.error}>{error}</span>}
      </div>

      <TextareaAutosize
        css={[styles.inputField(Boolean(error)), { resize: 'vertical' }]}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        translate="no"
        maxLength={maxLength}
        {...props}
      />
    </div>
  )
}

export default TextAreaGroup
